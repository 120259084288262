import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { EnterpriseConfigurations, Enterprise } from '../_interfaces/enterprise.interface';


@Injectable({
    providedIn: 'root'
})

export class EnterpriseService {
    url: string = environment.host

    constructor(
        private router: Router,
        private storage: Storage,
        private loginService: LoginService,
        private http: HttpClient) {

    }

    getEnterprise(enterpriseId: string)  {
        return this.http.get<Enterprise>(`${this.url}/enterprise/${enterpriseId}`, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('Authorization', this.loginService.getToken())
        });
    }

    updateEnterpriseConfig(enterpriseId : string , enterpriseConfig: EnterpriseConfigurations){
        let config = {
            config: enterpriseConfig
        }
        return this.http.patch<EnterpriseConfigurations>(`${this.url}/enterprise/${enterpriseId}`, config,{
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('Authorization', this.loginService.getToken())
        });

    }

}
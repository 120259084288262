import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { CausaService } from 'src/app/_services/causa.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-assign',
  templateUrl: './assign.page.html',
  styleUrls: ['./assign.page.scss'],
})
export class AssignPage implements OnInit {
  currentCausa;
  assignForm;
  allUsers = [];
  loadingCreate = false;

  constructor(private modalCtrl : ModalController,private navParams:NavParams,private causaService : CausaService,private formBuilder : FormBuilder ,private userService : UserService) { 
    this.assignForm = this.formBuilder.group({
      titular : ['',[Validators.required]],
      procurators : ['',[Validators.required]],
      captator : ['',[Validators.required]]      
    })    
    var currentCausa = navParams.get("causa");
    if(currentCausa){      
      this.currentCausa = currentCausa;
      for(var indice in currentCausa.assign){
        if(this.assignForm.controls[indice]){
          this.assignForm.controls[indice].setValue(currentCausa.assign[indice]);
        }                    
      }
    }
  }

  ngOnInit() {
    this.userService.listByEnterprise().then( promise =>{
      promise.subscribe( value => {
        this.allUsers = value;
        //console.log(this.allUsers);
      })
    })    
  }
  getTitulars(){
    return this.allUsers.filter( user => { return user.rol == "Titular" })
  }
  getProcurators(){
    const procurators = this.allUsers.filter( user => { return user.rol == "Procurador" })
    const titulars = this.getTitulars();
    return [...titulars,...procurators];
  }
  //Falta agregar permiso de asign
  saveAssign(){
    this.loadingCreate = true;

    var obj = { assign : this.assignForm.value }
    this.causaService.update(this.currentCausa.id,obj).subscribe( status => {
      this.loadingCreate = false;

      this.closeModal();
    })
  }  
  closeModal(){
    this.modalCtrl.dismiss();
  }
}

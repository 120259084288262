import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreaService {  
  private url: string = environment.host;  
  constructor(private http:HttpClient,private login:LoginService) {    
  }
  async list() {    
    return this.http.get<any[]>(`${this.url}/area/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  async listActiveAreasByEnterprise() {    
    return this.http.get<any[]>(`${this.url}/area/active/listByEnterprise` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
      .set('enterpriseId' , this.login.getEnterprise())   
    });
  }

  async listByEnterprise() { 
    return this.http.get<any[]>(`${this.url}/area/listByEnterprise` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('enterpriseId' , this.login.getEnterprise())   
    });
  }

  getOne(id){
    return this.http.get<any[]>(`${this.url}/area/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  update(id,area){
    delete area.__v;
    return this.http.patch<any[]>(`${this.url}/area/${id}` , area,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  insert(area){
    area.status = true;
    area['enterpriseId'] = this.login.getEnterprise();

    return this.http.post<any[]>(`${this.url}/area/` ,area, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

}

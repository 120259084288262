import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { CausaService } from './causa.service';

const ROLES = {
    "Admin":2048,
    "Titular":50,
    "Procurador":20,
    "Asistente":10,
    "RRSS":1
}

@Injectable({
  providedIn: 'root'
})
export class RolValidator {  
  
  constructor(private causaService : CausaService,private storage  : Storage,private router : Router) {    
  }
  isAssigned() : Promise<Boolean>{
    const causa = this.causaService.getCausa();
    const {assign} = causa;
    return new Promise( (resolve,reject)=>{
        this.storage.get('user').then( (val)=> {
            if(val){
                const isTitular = val.id === assign?.titular
                const isProcurator = assign?.procurators.includes(val.id);
                resolve(isTitular || isProcurator)
            }else{
                reject(false)
            }
        })
    })
  }
  isAdmin(){
      return new Promise( (resolve,reject)=>{
        this.storage.get('user').then((val) => {
            if(val){
                resolve( ROLES['Admin'] === ROLES[val.rol] )
            }else{
                reject(false)
            }
          })
      })
    
  }
  istitular(){
    return new Promise( (resolve,reject)=>{
        this.storage.get('user').then((val) => {
            if(val){
                resolve( ROLES['Titular'] == ROLES[val.rol] )
            }else{
                reject(false)
            }
          })
    })
  }
  isProcurator(){
    return new Promise( (resolve,reject)=>{
        this.storage.get('user').then((val) => {
            if(val){
                resolve( ROLES['Procurator'] == ROLES[val.rol] )
            }else{
                reject(false)
            }
          })
      })
  }
  isAsistent(){
    return new Promise( (resolve,reject)=>{
        this.storage.get('user').then((val) => {
            if(val){
                resolve( ROLES['Asistente'] == ROLES[val.rol] )
            }else{
                reject(false)
            }
          })
      })
  }
  isSocial(){
    return new Promise( (resolve,reject)=>{
        this.storage.get('user').then((val) => {
            if(val){
                resolve( ROLES['RRSS'] == ROLES[val.rol] )
            }else{
                reject(false)
            }
          })
      })
  }
  isBiggerThanRRSS(){
    const rrss = 1;
    return new Promise( (resolve,reject) => {
        this.storage.get('user').then((val) => {
            if(val){
                let currentRol = val.rol;
                let rol = ROLES[currentRol] || 0;
                resolve( rol > rrss )
            }else{
                reject(false)                
            }
        })    
    })
    
  }
  isBiggerThanAssistent(){
    const assistant = 10;
    return new Promise( (resolve,reject) => {
        this.storage.get('user').then((val) => {
            if(val){
                let currentRol = val.rol;
                let rol = ROLES[currentRol] || 0;
                resolve( rol > assistant )
            }else{
                reject(false)                
            }
        })    
    })    
  }
  isBiggerThanProcurator(){
    const procurator = 20;
    return new Promise( (resolve,reject) => {
        this.storage.get('user').then((val) => {
            if(val){
                let currentRol = val.rol;
                let rol = ROLES[currentRol] || 0;
                resolve( rol > procurator )
            }else{
                reject(false)                
            }
        })    
    })    
  }
  isBiggerThanTitular() : Promise<Boolean>{
    const titular = 50;
    return new Promise( (resolve,reject) => {
        this.storage.get('user').then((val) => {
            if(val){
                let currentRol = val.rol;
                let rol = ROLES[currentRol] || 0;
                resolve( rol > titular )
            }else{
                reject(false)                
            }
        })    
    })    
  }
  

}
